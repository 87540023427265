import React, { useEffect, useState } from 'react';
import './MainPage.css';
import {useCookies} from "react-cookie";
import {
    Box,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Typography
} from "@mui/material";
import {Table} from "reactstrap";

function MainPage(props) {
    const EMAIL_DOMAIN = 'landservices.com.au';
    const [msg, setMsg] = useState('');
    const [error, setError] = useState('');
    const [cookies] = useCookies(['XSRF-TOKEN']);
    const initialFormState = {
        name: '',
        email: '',
        mfaEnrollments: []
    };
    const [user, setUser] = useState(initialFormState);
    const [showConfirm, setShowConfirm] = useState(false);
    const [enrollMethod, setEnrollMethod ] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [internal, setInternal] = useState(false);

    useEffect(() => {
        fetch(`/api/loggedInUser`)
            .then(response => response.json())
            .then(data => {
                setUser(data);
                if(data && data.email.endsWith(EMAIL_DOMAIN)){
                    setInternal(true);
                }
            });
    }, [setUser]);

    useEffect(() => {
        getSessions();
    }, []);

    const handleResetPwd = async () => {
        const response = await fetch(`/api/user/resetPwd`, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: props.clientId ? props.clientId : 'default',
            credentials: 'include'
        });
        const body = await response.text();
        response.ok ? setMsg(body) : setError(body);
    }

    const addMfa = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ':3000') {
            port = ':8080';
        }
        fetch('/api/logout', {
            method: 'POST', credentials: 'include',
            headers: { 'X-XSRF-TOKEN': cookies['XSRF-TOKEN'] } // <.>
        })
            .then(res => res.json())
            .then(response => {
                window.location.href = `${response.logoutUrl}&returnTo=${window.location.protocol}//${window.location.hostname}${port}/api/private`;
            })
        ;
    }

    const changeMfa = (event, enroll_id) => {
        setShowConfirm(!showConfirm);
        setEnrollMethod(enroll_id);
    }

    const logout = () => {
        fetch('/api/logout', {
            method: 'POST', credentials: 'include',
            headers: { 'X-XSRF-TOKEN': cookies['XSRF-TOKEN'] } // <.>
        })
            .then(res => res.json())
            .then(response => {
               window.location.href = `${response.logoutUrl}&returnTo=${window.location.origin}/logoutSuccess.html`;
            });
    }

    const showfirmbtn = () => {
        fetch(`/api/userMfaMethod/${encodeURI(enrollMethod)}`, {
                method: 'DELETE',
                headers: {
                    'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            }).then(() => {
                addMfa();
            });
        }

    const getSessions = () => {
        fetch(`/api/getSessions`)
            .then(response => response.json())
            .then(result =>
                {
                    // If return true that means MFA is still within 15 mins
                    if (result) {
                        setSessionFunc();
                    } else {
                        setDisabled(true);
                    }
                }
            );
    }

    const setSessionFunc = () => {
        if(!disabled) {
            setTimeout(() => {
                getSessions();
            }, 60000);
        }
    };

    return (
    <div className="barlow-regular home-container">
        <div className="header barlow-medium">
            <p>Manage Authentication</p>
        </div>
        <br/>
        {internal ? <div style={{height: 60}}></div> :
        <div>
            <Typography variant="h5">
            <p className="resetPassword">Reset password</p></Typography>
            <span>Resetting your password will trigger an email to your inbox with a password reset link.</span>
            <div className="reset-pwd-btn">
                <Button variant="contained" color="primary" onClick={handleResetPwd}>
                    <span className="material-icons">repeat</span>&nbsp;&nbsp;RESET PASSWORD</Button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {msg && <p>{msg}</p>}
            </div>
            <div className="mfa-container">
                <Typography variant="h5"><p>MFA preferences</p></Typography>
            </div>
            { showConfirm && (
                <div className="contaier_showbox">
                    <span className="contaier_showbox_back"></span>
                    <div className="container_showbox_title">
                        <h3>Manage Authentication</h3>
                        <p>Your current method will be deleted, and then a new method will be setup.</p>
                        <div className="container_showbox_footers">
                            <span onClick = {() => {  showfirmbtn() }}>CONFIRM</span>
                            <span onClick = {() => {  setShowConfirm(!showConfirm) }} className="container_showbox_footers_cancel">CANCEL</span>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <TableContainer component={Box} sx={{width: '100vh'}}>
                    <Table aria-label="member permission table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Authentication type</TableCell>
                                <TableCell>Details</TableCell>
                                <TableCell align="right"><div className="action-font">Actions</div></TableCell>
                            </TableRow>
                        </TableHead>
                        {user.mfaEnrollments.length > 0 ?
                        <TableBody>
                            {user.mfaEnrollments.map((enrollment) => (
                                <TableRow key={enrollment.id}>
                                    <TableCell>{enrollment.type}</TableCell>
                                    <TableCell>{enrollment.phone_number ? enrollment.phone_number : enrollment.name}</TableCell>
                                    <TableCell align="right">
                                    <div className="mfa-btn">
                                        <Button variant="contained" disabled={disabled}
                                         onClick={(e) => {
                                             changeMfa(e, enrollment.id);}}>
                                         CHANGE MFA METHOD</Button></div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody> :
                        <TableBody><tr><td><p>Currently there is no authentication method, please add one!</p></td></tr></TableBody>}
                    </Table>
                </TableContainer>
            </div>
            {disabled ?
                <div className="disabledMsg">
                    <span>For security reasons please log out then log back in to confirm your</span><br />
                    <span>identity before updating your Multi-Factor Authentication method.</span>
                </div> : <div></div>
            }
        </div>}
        {!props.clientId &&
            <div className="logout-section">
                <Typography variant="h5"><p>Logout</p></Typography>
                <span>Logging out will end your authentication session. If you have any applications open these will remain active until they timeout or you logout.</span>
                <div className="logoutBtn"><Button variant="contained" onClick={logout}>LOGOUT</Button></div>
            </div>
        }
        <div className="footer">

            <div className="copyright">&copy;Copyright 2024 | Land Services SA (ACN 618 229 815)</div>
            <div className="privacy"><a href="https://www.landservices.com.au/sailis-terms-of-use">Privacy Policy</a></div>
            <div className="terms"><a href="https://www.landservices.com.au/sailis-terms-of-use">Terms of user</a></div>
        </div>
    </div>
    );
}

export default MainPage;