import React, { useEffect, useState } from 'react';
import './App.css';
import MainPage from "./MainPage";
import { Container } from 'reactstrap';
import { useCookies } from 'react-cookie';

const Home = () => {

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(undefined);
  const [cookies] = useCookies(['XSRF-TOKEN']); // <.>
  const [clientId, setClientId] = useState(() => localStorage.getItem("clientId") || "");

  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search); // Extract query params from URL
    const clientID = urlParams.get("clientID");
    console.log("Client ID:", clientID);
    if (clientID) {
      setClientId(clientID);
      localStorage.setItem("clientId", clientID); // Save to local storage
    }
    fetch('api/user', { credentials: 'include' }) // <.>
      .then(response => response.text())
      .then(body => {
        if (body === '') {
          setAuthenticated(false);
          login();
        } else {
          window.localStorage.setItem('userdata', body);
          setUser(JSON.parse(body));
          setAuthenticated(true);
        }
        setLoading(false);
      });
  }, [setAuthenticated, setLoading, setUser])

  const login = () => {
    let port = (window.location.port ? ':' + window.location.port : '');
    if (port === ':3000') {
      port = ':8080';
    }
    window.location.href = `//${window.location.hostname}${port}/api/private`;
  }

  const logout = () => {
    fetch('/api/logout', {
      method: 'POST', credentials: 'include',
      headers: { 'X-XSRF-TOKEN': cookies['XSRF-TOKEN'] } // <.>
    })
      .then(res => res.json())
      .then(response => {
        window.location.href = `${response.logoutUrl}&returnTo=${window.location.origin}`;
      });
  }

  const message = user ?
    <h2></h2> :
    <p>Please log in to manage your user details.</p>;

  const button = authenticated ?
    <div>
      <MainPage usersub={user.sub} clientId={clientId} />
      <br/>
    </div> : <div></div>

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Container fluid>
        {button}
      </Container>
    </div>
  );
}

export default Home;
